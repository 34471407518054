<template>
  <v-app id="ppt">

<v-card class="overflow-hidden">
      <v-app-bar
        absolute
        color="#000"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          <v-img src="./assets/transport_top2.png"></v-img>
          
        </v-toolbar-title>
  
        <v-spacer></v-spacer>
  <div>
  <a href="tel:7198889000">
       <!-- <v-hover v-slot="{ hover }">  
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="mx-auto"         
        >          -->
        <v-img 
        src="./assets/transport_pn.png" alt="719-888-9000" max-width="238" class="mr-4" ></v-img>
        <!-- </v-card>
       </v-hover> -->
  </a>
  </div>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="100vh"
      >
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center pb-0">
              <h1 class="mt-5 pt-5">Need a ride? Schedule a pick-up!</h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              Welcome to Pikes Peak Transport ~ We are a Colorado Springs based locally owned company that is state licensed and commercially insured. We take pride in providing our community with reliable, on-time and safe transportation services. We specialize in airport transportation service to and from Colorado Springs airport and El Paso County to Denver International airport.
            </v-col>
          </v-row>

          <v-row>
          <v-col cols="12" lg="6" md="6" sm="12" class="d-flex-column">
            <!-- {{`${salve}`}} -->
            <!-- <div v-html="`${salve}`"></div> -->
<!-- <div class="mb-5">We are a Colorado Springs based locally owned company that is state licensed and commercially insured. We take pride in providing our community with reliable, on-time and safe transportation services. We specialize in airport transportation service to and from Colorado Springs airport and El Paso County to Denver International airport. </div> -->
<div><b>Book your ride with us!</b> We will pick you up on time and in style to destinations such as: <b>The Colorado Springs Airport, Pueblo Airport, Denver International Airport</b> and more. Give us a call for quotes and scheduling.</div>
<div class="my-5">Our well-trained and courteous drivers are ready to transport you from anywhere in Colorado Springs and El Paso County to most cities and counties in the state of Colorado. We also provide transportation to Colorado Springs and El Paso County from most cities and counties in the state of Colorado.</div>
<!-- <div class="my-5">Call or email for reservations and price quotes. Advanced booking is recommended.  Pre-payment for all advanced booking is required for scheduling.</div> -->
<div class="my-5">Contact us at: <b><a href="tel:7198889000">(719) 888-9000</a></b> or send an email with your name, pick up date, time, and pick up and drop off location to: <a href="mailto:dispatch@pikespeakcabllc.com">dispatch@pikespeakcabllc.com</a>.  We will respond as soon as possible with quotes and availability.  Advanced booking is recommended.</div>



<!-- <div class="my-5"><ul>
  <li>Full refund for cancellation 48 hours prior to pick up.</li>
<li>50% refund for cancellation 24 hours prior to pick up.</li>
<li>On the day of pick up there is no refund.</li>
<li>Upon scheduling, rides must be paid in full.</li>
<li>For airport pick up and drop offs, If there is a plane delay on arrival, there is a $20/HR surcharge for waiting time. If your inbound flight is cancelled, we can reschedule your pick-up.</li>
</ul>
</div> -->

          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" class="d-flex justify-center align-top child-flex">
            <!-- child-flex -->
            
<v-img
              :src="require('./assets/pp_logo2.jpg')"
              class="my-1 rounded center"
              contain
              max-width="600"
              
            />
          </v-col>
</v-row>

<!-- <v-row class="text-center pt-4">
          <v-col cols="6">
            <v-card>
              <v-card-title primary-title>
                <div>
                  <h3 class="headline mb-0">From COS</h3>
                  <div>Rides To/From Colorado Springs Airport</div>
                </div>
              </v-card-title>

              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Location</th>
                      <th class="text-left">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in cos" :key="i">
                      <td><b>{{ item.place }}</b> {{ item.address }}</td>
                      <td>${{ item.price }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title primary-title>
                <div>
                  <h3 class="headline mb-0">From DIA</h3>
                  <div>Rides To/From Denver International Airport</div>
                </div>
              </v-card-title>

              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Location</th>
                      <th class="text-left">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in dia" :key="i">
                      <td><b>{{ item.place }}</b> {{ item.address }}</td>
                      <td>${{ item.price }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              
            </v-card>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" class="my-3 py-3">
          <v-spacer></v-spacer>
          </v-col>
        </v-row>
          
        </v-container>
      </v-sheet>


      <v-footer color="#000" absolute class="font-weight-medium">
      <v-col
          class="text-center"
          cols="12"
        >
          <strong style="color: #fff">Pikes Peak Transport - <a href="tel:7198889000">719-888-9000</a></strong>
        </v-col>
      </v-footer>
          </v-card>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
    //HelloWorld,
  },

  data: () => ({

    salve: `We are a Colorado Springs based locally owned company that is state licensed and commercially insured. We take pride in providing our community with reliable, on-time and safe transportation services. We specialize in airport transportation service to and from Colorado Springs airport and El Paso County to Denver International airport. \n\n

Our well-trained and courteous drivers are ready to transport you from anywhere in Colorado Springs and El Paso County to all cities and counties in the state of Colorado. We also provide transportation to Colorado Springs and El Paso County from all cities and counties in the state of Colorado.

Call or email for reservations and price quotes. Advanced booking is recommended.  Pre-payment for all advanced booking is required for scheduling.

Flat rates are listed below:`,


    items: [
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me 2' },
    ],

    cos: [
      { place: "Fairfield Inn & Suites", address: "15275 Struthers Rd", price: 70 },
      { place: "My Place Hotel", address: "369 Gleneagle Gate Vw", price: 65 },
      { place: "Rampart Lodge", address: "3130 Academy Dr USAFA", price: 65 },
      { place: "The Lodge at Flying Horse", address: "1880 Weiskopf Pt", price: 65 },
      { place: "Drury Inn and Suites", address: "1170 Interquest Pkwy", price: 60 },
      { place: "Holiday Inn Express", address: "9856 Federal Dr", price: 60 },
      { place: "Hampton Inn and Suites", address: "1307 Republic Dr", price: 60 },
      { place: "SpringHill Suites", address: "1320 Republic Dr", price: 60 },
      { place: "Residence Inn", address: "9805 Federal Dr", price: 60 },
      { place: "Homewood Suites", address: "9130 Explorer Dr", price: 55 },
      { place: "Hilton Garden Inn", address: "1810 Briargate Pkwy", price: 55 },
      { place: "Academy Hotel", address: "8110 N Academy Blvd", price: 50 },
      { place: "Super 8/LaQuinta", address: "8135-8155 N Academy Blvd", price: 50 },
      { place: "Motel 6", address: "8280 Voyager Pkwy", price: 50 },
      { place: "Days Inn", address: "8350 Razorback Rd", price: 50 },
      {
        place: "Baymont by Wyndham/Comfort Ste", address: "1055 Kelly Johnson Blvd",
        price: 50,
      },
      { place: "Hampton Inn", address: "N Academy 1060 Kelly Johnson Blvd", price: 50 },
      {
        place: "Econo Lodge", address: "N Academy/SleepInn 1075 Kelly Johnson Blvd",
        price: 50,
      },
      { place: "TownePlace Suites", address: "4760 Centennial Blvd", price: 45 },
      { place: "Embassy Suites", address: "7290 Commerce Center Dr", price: 45 },
      { place: "Fairfield Inn & Suites", address: "7085 Commerce Center Dr", price: 45 },
      { place: "Best Western Plus Peak", address: "7265 Commerce Center Dr", price: 45 },
      { place: "Wingate by Windham", address: "7245 Commerce Center Dr", price: 45 },
      {
        place: "NewVisionHotelsTwo/StaybridgeSte", address: "7130 Commerce Center Dr",
        price: 45,
      },
      { place: "Holiday Inn Express", address: "7110 Commerce Center Dr", price: 45 },
      { place: "Comfort Inn/InTownSts/Extended Stay", address: "Corporate Dr", price: 45 },
      { place: "Hyatt House", address: "5805 Delmonico Dr", price: 40 },
      { place: "Colorado Springs Marriot", address: "5580 Tech Center Dr", price: 40 },
      { place: "Garden of the Gods Resort", address: "3320 Mesa Rd", price: 40 },
      { place: "Hyatt Place", address: "503 W Garden of the Gods", price: 40 },
      { place: "Cliff House", address: "306 Canon Ave", price: 40 },
      { place: "Best Western Plus (Fillmore)", address: "3150 N Chestnut St", price: 35 },
      { place: "Woodsprings Suites (Fillmore)", address: "3350 N Chestnut St", price: 35 },
      { place: "Super 8 (Fillmore)", address: "3271 N Chestnut St", price: 35 },
      { place: "La Quinta (Fillmore)", address: "4385 Sinton Rd", price: 35 },
      { place: "Quality Inn & Suites", address: "555 W Garden of the Gods", price: 35 },
      { place: "The Antlers", address: "4 S Cascade Ave", price: 30 },
      { place: "Hilton Garden Inn", address: "125 S. Cascade Ave", price: 30 },
      { place: "The Broadmoor", address: "1 Lake Ave", price: 30 },
      { place: "Greyhound Station", address: "120 S Weber St", price: 30 },
    ],
    dia: [
      { place: "Fairfield Inn & Suites", address: "15275 Struthers Rd", price: 150 },
      { place: "My Place Hotel", address: "369 Gleneagle Gate Vw", price: 150 },
      { place: "Rampart Lodge", address: "3130 Academy Dr USAFA", price: 160 },
      { place: "Drury Inn and Suites", address: "1170 Interquest Pkwy", price: 155 },
      { place: "Holiday Inn Express", address: "9856 Federal Dr", price: 155 },
      { place: "Hampton Inn and Suites", address: "1307 Republic Dr", price: 155 },
      { place: "SpringHill Suites", address: "1320 Republic Dr", price: 155 },
      { place: "Residence Inn", address: "9805 Federal Dr", price: 155 },
      { place: "Homewood Suites", address: "9130 Explorer Dr", price: 155 },
      { place: "Hilton Garden Inn", address: "1810 Briargate Pkwy", price: 155 },
      { place: "The Academy Hotel", address: "8110 N Academy Blvd", price: 160 },
      { place: "Super 8/LaQuinta", address: "8135-8155 N Academy Blvd", price: 160 },
      { place: "Motel 6", address: "8280 Voyager Pkwy", price: 160 },
      { place: "Days Inn", address: "8350 Razorback Rd", price: 160 },
      {
        place: "Baymont by Wyndham/Comfort Ste", address: "1055 Kelly Johnson Blvd",
        price: 160,
      },
      { place: "Hampton Inn N Academy", address: "1060 Kelly Johnson Blvd", price: 160 },
      {
        place: "Econo Lodge N Academy/SleepInn", address: "1075 Kelly Johnson Blvd",
        price: 160,
      },
      { place: "TownePlace Suites", address: "4760 Centennial Blvd", price: 170 },
      { place: "The Embassy Suites", address: "7290 Commerce Center Dr", price: 165 },
      { place: "Fairfield Inn & Suites", address: "7085 Commerce Center Dr", price: 165 },
      { place: "Best Western Plus Peak", address: "7265 Commerce Center Dr", price: 165 },
      { place: "Windgate by Windham", address: "7245 Commerce Center Dr", price: 165 },
      {
        place: "NewVisionHotelsTwo/StaybridgeSte", address: "7130 Commerce Center Dr",
        price: 165,
      },
      { place: "Holiday Inn Express", address: "7110 Commerce Center Dr", price: 165 },
      { place: "Comfort Inn/InTownSts/Extended Stay", address: "Corporate Dr", price: 165 },
      { place: "The Hyatt House", address: "5805 Delmonico Dr", price: 165 },
      { place: "Colorado Springs Marriot", address: "5580 Tech Center Dr", price: 165 },
      { place: "Garden of the Gods Resort", address: "3320 Mesa Rd", price: 175 },
      { place: "Hyatt Place", address: "503 W Garden of the Gods", price: 170 },
      { place: "Quality Inn & Suites", address: "555 W Garden of the Gods", price: 170 },
      { place: "Best Western Plus", address: "(Fillmore) 3150 N Chestnut St", price: 170 },
      { place: "Woodsprings Suites", address: "(Fillmore) 3350 N Chestnut St", price: 170 },
      { place: "Super 8 (Fillmore)", address: "3271 N Chestnut St", price: 170 },
      { place: "La Quinta (Fillmore)", address: "4385 Sinton Rd", price: 170 },
      { place: "The Antlers", address: "4 S Cascade Ave", price: 175 },
      { place: "Hilton Garden Inn", address: "125 S Cascade Ave", price: 175 },
      { place: "The Broadmoor", address: "1 Lake Ave", price: 175 },
      { place: "Cliff House", address: "306 Canon Ave", price: 185 },
      { place: "Greyhound Station", address: "120 S Weber St", price: 175 },
      { place: "Colorado Springs Airport", address: "7770 Milton E Proby", price: 180 },
    ],
  }),
};
</script>

<style>

</style>
